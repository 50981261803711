import '@reduxjs/toolkit';
import { PaymentElement } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { Button, ScrollbarContainer } from 'UI';
import { TermEnum as uiTermEnum } from 'UI/PriceLabel/PriceLabel';
import StyledText from 'UI/StyledText';
import { DirectionDto, SubscribeDtoTypeEnum, SubscriptionPeriod } from 'api/generated';
import { AxiosError } from 'axios';
import Loader from 'components/Loader';
import { levelMapping, newSubscribeTypes } from 'constant';
import { useAsyncAction, useGoogleAnalytics, useStripeUnauthorizedForm } from 'hooks';
import { useCoupon } from 'hooks';
import { TermEnum } from 'pages/CreateUser/CreateUser';
import { CreateUserModalsEnum, IframeEvents } from 'pages/CreateUser/CreateUser';
import { UnauthorizedStripeProvider } from 'providers';
import React, { FC, useEffect, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import styled, { css } from 'styled-components';
import { respondToWidth } from 'styles/general/respondTo';

import DefaultModal from '../../../UI/Modals/DefaultModal';
import { DefaultModalProps } from '../../../UI/Modals/DefaultModal/DefaultModal';
import { Coupon, CouponForCertificate, Price, PriceInstallment, Secure } from '../PaymentModal/components';
import { SubscribeDtoType } from '../PaymentModal/components/CouponForCertificate/components/SuccessCoupon/SuccessCoupon';
import { AuthorizedInput } from './components';

declare const dataLayer: any;

type SignUpModalProps = {
  email: string;
  openSuccessModal?: () => void;
  courseName: string;
  subscribeType: SubscribeDtoTypeEnum;
  currentPrice: number;
  showCloseButton?: boolean;
  oldPrice: number;
  isCertificate: boolean;
  term: TermEnum;
  courseId: string;
  defaultCoupon: any;
  currentModal: CreateUserModalsEnum;
  defaultFirstCouponInfo: any;
} & DefaultModalProps;

const SignUpModal: FC<SignUpModalProps> = ({
  email,
  openSuccessModal,
  courseName,
  showCloseButton,
  subscribeType,
  isCertificate,
  currentPrice,
  oldPrice,
  term,
  courseId,
  defaultCoupon,
  currentModal,
  defaultFirstCouponInfo,
  ...props
}) => {
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [invoiceId, setInvoiceId] = useState('');
  const [isLoadingChangeFirstCoupon, setIsLoadingChangeFirstCoupon] = useState<boolean>(false);
  const [defaultCouponInfo, setDefaultCouponInfo] = useState<any>({});
  const [defCop, setDefCop] = useState<string | undefined>(defaultCoupon);
  const dispatch = useAppDispatch();
  const { getEventSubscriptionPayPressedGA } = useGoogleAnalytics();
  const [certificateFinalPrice, setCertificateFinalPrice] = useState<any>();
  const isUseFirstMonthCoupon = !!defaultFirstCouponInfo.firstCouponId;
  const {
    couponState,
    isInvalidCoupon,
    removeCoupon,
    setIsInvalidCoupon,
    isDefaultCoupon,
    onChangePromoCode,
    isCertificateLoading,
    isLoading: isDeleteLoading,
    isChangePromoCodeLoading,
  } = useCoupon({
    courseId,
    subscribeType,
    term,
    amount: currentPrice,
    defaultCouponCode: defaultCoupon,
    setClientSecret,
    setInvoiceId,
    setDefCop,
    isCertificate,
    setDefaultCouponInfo,
    invoiceId,
    isUpdate: false,
  });

  const { couponErrorMessage, isSuccessCoupon, discountAmount, discountValue, promoCode } = couponState;

  const [error, setError] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [direction, setDirection] = useState<DirectionDto | undefined>(undefined);
  const [getDirectionByUrl] = useAsyncAction(actions.direction.getDirectionByUrl);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  const isDarkMode = useAppSelector(selectors.settings.isDarkMode);
  const url = window.location.origin;
  const mainColor = direction?.mainColorHex;
  const termOfUseUrl = direction?.termsOfServiceUrl;
  const privacyPolicyUrl = direction?.privacyPolicyUrl;
  const logo = isDarkMode ? direction?.darkCheckoutLogoUrl : direction?.checkoutLogoUrl;
  // @ts-ignore
  const [purchaseCourseAction] = useAsyncAction(actions.payments.purchaseCourse);
  // @ts-ignore
  const [subscribeCourseAction] = useAsyncAction(actions.payments.subscribeCourse);
  // @ts-ignore
  const [purchaseCourseInstallmentAction] = useAsyncAction(actions.payments.purchaseCourseInstallment);
  const { getEventSuccessfulPurchaseForLandingGA, getEventSuccessfulSubscribeForLandingGA } = useGoogleAnalytics();

  const postMessage = (event: string, data?: any) => {
    parent.postMessage({ event, data }, direction?.landingUrl || '');
  };

  const getCurrentDirection = async (url: string) => {
    const direction = await getDirectionByUrl({ url });

    setDirection(direction);
  };

  useEffect(() => {
    getCurrentDirection(url);
  }, [url]);

  const handlePurchaseCourse = async () => {
    try {
      const { clientSecret, invoiceId, coupons } = await purchaseCourseAction({
        courseId,
        promocode: defCop || promoCode,
        source: 'landing page',
        useToken: false,
        level: subscribeType,
      });
      setInvoiceId(invoiceId);
      const discountValue =
        coupons[0]?.coupon?.discountType === 'percentage'
          ? (currentPrice * coupons[0]?.coupon?.discountAmount) / 100
          : coupons[0]?.coupon?.discountAmount;
      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: !!coupons[0]?.coupon?.promoCode,
        couponErrorMessage: null,
        promoCode: coupons[0]?.coupon?.promoCode,
        discountAmount: coupons[0]?.coupon?.discountAmount,
        discountValue,
      });
      return clientSecret;
    } catch (error: any) {
      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: false,
        couponErrorMessage: error?.message || 'An error occurred',
        promoCode: '',
      });
      setIsInvalidCoupon(true);
      throw error;
    }
  };

  const handlePurchaseCourseInstallment = async () => {
    try {
      const { clientSecret, invoiceId, coupons } = await purchaseCourseInstallmentAction({
        courseId,
        promocode: promoCode,
        source: 'landing page',
        level: subscribeType,
        useFirstMonthDiscount: isUseFirstMonthCoupon,
      });
      setInvoiceId(invoiceId);
      const discountValue =
        coupons[0]?.coupon?.discountType === 'percentage'
          ? (currentPrice * coupons[0]?.coupon?.discountAmount) / 100
          : coupons[0]?.coupon?.discountAmount;

      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: !!coupons[0]?.coupon?.promoCode,
        couponErrorMessage: null,
        promoCode: coupons[0]?.coupon?.promoCode,
        discountAmount: coupons[0]?.coupon?.discountAmount,
        discountValue,
      });

      return clientSecret;
    } catch (error: any) {
      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: false,
        couponErrorMessage: error?.message || 'An error occurred',
        promoCode: '',
      });
      setIsInvalidCoupon(true);
      throw error;
    }
  };

  const handleSubscribeCourse = async () => {
    try {
      const { clientSecret, invoiceId, coupons } = await subscribeCourseAction({
        courseId,
        level: subscribeType,
        period: term.toLowerCase() as SubscriptionPeriod,
        source: 'landing page',
        promocode: defCop || promoCode,
        useToken: false,
      });
      setInvoiceId(invoiceId);

      const discountValue =
        coupons[0]?.coupon?.discountType === 'percentage'
          ? (currentPrice * coupons[0]?.coupon?.discountAmount) / 100
          : coupons[0]?.coupon?.discountAmount;

      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: !!coupons[0]?.coupon?.promoCode,
        couponErrorMessage: null,
        promoCode: coupons[0]?.coupon?.promoCode,
        discountAmount: coupons[0]?.coupon?.discountAmount,
        discountValue,
      });

      return clientSecret;
    } catch (error: any) {
      setDefaultCouponInfo({
        ...couponState,
        isSuccessCoupon: false,
        couponErrorMessage: error?.message || 'An error occurred',
        promoCode: '',
      });
      setIsInvalidCoupon(true);
      throw error;
    }
  };

  const isLifetimeTerm = term === TermEnum.LIFE_TIME;

  const getSecret = async () => {
    try {
      const clientSecret =
        isLifetimeTerm && isCertificate
          ? await handlePurchaseCourseInstallment()
          : isLifetimeTerm
          ? await handlePurchaseCourse()
          : await handleSubscribeCourse();

      if (clientSecret) {
        setClientSecret(clientSecret);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingCustomer(false);
    }
  };

  useEffect(() => {
    getSecret();
  }, []);

  const {
    handleSubmit: handleSubmitStripe,
    setElements,
    setStripe,
    stripe,
    errorMessage,
    isAccountExists,
    isLoading: isLoadingStripe,
    disabled,
    isSucceeded,
  } = useStripeUnauthorizedForm(clientSecret, courseId);

  const isLoading = isLoadingStripe;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');
    getEventSubscriptionPayPressedGA();
    await handleSubmitStripe(e);
  };

  const pushDataLayer = async () => {
    if (!clientSecret) {
      return;
    }
    const paymentIntent = (await stripe?.retrievePaymentIntent(clientSecret))?.paymentIntent;
    dataLayer.push({
      event: 'successful_purchase',
      transactionId: paymentIntent?.id,
      course_name: courseName,
      promo_code: promoCode,
      item_name: subscribeType,
      item_id: courseId,
      price: finalPrice,
      term: term,
      enhanced_conversion_data: {
        email: email,
      },
    });
  };

  useEffect(() => {
    if (isSucceeded) {
      const onPaymentConfirm = async () => {
        try {
          setError('');
          openSuccessModal?.();
          pushDataLayer();
          term === TermEnum.LIFE_TIME
            ? getEventSuccessfulPurchaseForLandingGA()
            : getEventSuccessfulSubscribeForLandingGA();
        } catch (error) {
          setError((error as AxiosError).message);
        }
      };

      onPaymentConfirm();
    }
  }, [isSucceeded]);

  useEffect(() => {
    console.log('effect hook');
    const handleStripeOperation = async () => {
      if (currentModal === CreateUserModalsEnum.SUCCESS_CREATE_ACCOUNT && clientSecret) {
        console.log('hook condition');
        try {
          const paymentIntent = (await stripe?.retrievePaymentIntent(clientSecret))?.paymentIntent;
          const email = paymentIntent?.receipt_email;

          const data = {
            term,
            productType: newSubscribeTypes[subscribeType],
            courseName,
            courseId,
            currentPrice: finalPrice,
            promoCode,
            oldPrice,
            currency: paymentIntent?.currency,
            transactionId: paymentIntent?.id,
            created_date: paymentIntent?.created ? new Date(paymentIntent?.created * 1000).toString() : undefined,
          };

          if (email) {
            dispatch(actions.auth.setEmail(email));
          }

          postMessage(IframeEvents.SUCCESSFUL_PURCHASE, data);
          console.log('success post message', data);
        } catch (error) {
          console.log('error post message', error);
          console.error(error);
        }
      }
    };

    handleStripeOperation();
  }, [currentModal, clientSecret]);

  const finalPrice =
    (defaultCouponInfo?.discountValue || discountValue) && currentPrice
      ? currentPrice - (defaultCouponInfo?.discountValue || discountValue)
      : currentPrice;

  return (
    <DefaultModal
      {...props}
      headerContainerCSS={headerContainerCSS}
      containerCSS={containerCSS}
      showCloseButton={showCloseButton}
      titleCSS={titleCSS}
      modalCSS={modalCSS}
      shouldCloseOnOverlayClick={false}
      closeButtonCSS={closeButtonCSS}>
      <DescriptionContainer mainColor={mainColor}>
        <LogoContainer>
          <LogoImg src={logo} />
        </LogoContainer>

        {isCertificate && (
          <PriceInstallment
            amount={currentPrice}
            isDefaultCoupon={isDefaultCoupon}
            discountValue={defaultCouponInfo.discountValue || discountValue}
          />
        )}

        {!isCertificate && (
          <Price
            amount={currentPrice}
            term={term as uiTermEnum}
            isDefaultCoupon={isDefaultCoupon}
            discountValue={defaultCouponInfo.discountValue || discountValue}
          />
        )}

        <NameContainer>
          <CourseNameText>{courseName}</CourseNameText>
          <CourseSubNameText>{levelMapping[subscribeType]}</CourseSubNameText>
        </NameContainer>

        {isCertificate ? (
          <CouponForCertificate
            subscribeType={subscribeType as SubscribeDtoType}
            isDefaultCoupon={isDefaultCoupon}
            defaultCouponCode={defCop || defaultCoupon}
            setIsLoadingChangeFirstCoupon={setIsLoadingChangeFirstCoupon}
            isSuccessCoupon={defaultCouponInfo?.isSuccessCoupon || isSuccessCoupon}
            courseName={courseName || ''}
            setCertificateFinalPrice={setCertificateFinalPrice}
            removeCoupon={removeCoupon}
            amount={currentPrice}
            setClientSecret={setClientSecret}
            setInvoiceId={setInvoiceId}
            oldAmount={oldPrice}
            promoCode={promoCode}
            invoiceId={invoiceId}
            discountAmount={defaultCouponInfo?.discountAmount || discountAmount}
            discountValue={defaultCouponInfo?.discountValue || discountValue}
            couponErrorMessage={defaultCouponInfo.couponErrorMessage || couponErrorMessage}
            invalidCoupon={isInvalidCoupon}
            setClearCoupon={() => setIsInvalidCoupon(false)}
            handleChangePromoCode={onChangePromoCode}
            disable={isLoading || isChangePromoCodeLoading}
          />
        ) : (
          <Coupon
            subscribeType={levelMapping[subscribeType]}
            isDefaultCoupon={Boolean(defCop) || isDefaultCoupon}
            defaultCouponCode={defCop || defaultCoupon}
            isSuccessCoupon={defaultCouponInfo?.isSuccessCoupon || isSuccessCoupon}
            courseName={courseName || ''}
            removeCoupon={removeCoupon}
            setCertificateFinalPrice={setCertificateFinalPrice}
            oldAmount={oldPrice}
            promoCode={promoCode}
            amount={currentPrice}
            discountAmount={defaultCouponInfo?.discountAmount || discountAmount}
            discountValue={defaultCouponInfo?.discountValue || discountValue}
            couponErrorMessage={defaultCouponInfo.couponErrorMessage || couponErrorMessage}
            invalidCoupon={isInvalidCoupon}
            setClearCoupon={() => setIsInvalidCoupon(false)}
            handleChangePromoCode={onChangePromoCode}
            disable={isDeleteLoading || isChangePromoCodeLoading || isCertificateLoading}
          />
        )}
      </DescriptionContainer>
      <Content>
        <Form onSubmit={handleSubmit}>
          <div>
            <StripeFormWrapper>
              {isLoadingCustomer && !clientSecret && (
                <LoaderWrapper>
                  <Loader width={54} height={54} />
                </LoaderWrapper>
              )}
              <UnauthorizedStripeProvider
                showEmptyState={true}
                setElements={(elements: StripeElements | null) => setElements(elements)}
                setStripe={setStripe}
                onConfirm={handleSubmitStripe}
                clientSecret={clientSecret}>
                <>
                  <Text>Or with Card/Cash App</Text>
                  <AuthorizedInput setIsAuthorized={setIsAuthorized} courseId={courseId} />
                  <PaymentElement
                    options={{
                      readOnly: !isAuthorized || !clientSecret || finalPrice <= 0,
                      terms: { card: 'never', usBankAccount: 'never', bancontact: 'never' },
                    }}
                  />
                </>
              </UnauthorizedStripeProvider>
              <ErrorText>
                {!isAccountExists ? (
                  error || errorMessage
                ) : (
                  <div style={{ display: 'flex' }}>
                    <p>
                      We noticed that you already have a trial account with {email}. Please
                      <a
                        style={{ textDecoration: 'underline', marginLeft: '3px', marginRight: '3px' }}
                        href="https://study.traineracademy.org/"
                        target="_blank"
                        rel="noreferrer">
                        log in
                      </a>
                      here to purchase.
                    </p>
                  </div>
                )}
              </ErrorText>
            </StripeFormWrapper>
          </div>
          <Secure />
          <Footer>
            <Button
              isLoading={
                isLoading ||
                !clientSecret ||
                isDeleteLoading ||
                isChangePromoCodeLoading ||
                isLoadingChangeFirstCoupon ||
                isCertificateLoading
              }
              disabled={disabled || !clientSecret || !isAuthorized}
              type="submit"
              variant="primary">
              {`Enroll Now: $${certificateFinalPrice || finalPrice} USD`}
            </Button>
            <LinkContainer>
              <Link as="a" href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                Privacy
              </Link>
              <Link as="a" href={termOfUseUrl} target="_blank" rel="noopener noreferrer">
                Terms
              </Link>
            </LinkContainer>
            <SecureTextContainer>
              {term.toLowerCase() !== TermEnum.LIFE_TIME && (
                <SecureText font="subhead_medium_13">Renews automatically. Cancel anytime.</SecureText>
              )}
            </SecureTextContainer>
          </Footer>
        </Form>
      </Content>
    </DefaultModal>
  );
};

export default SignUpModal;

const modalCSS = css`
  max-height: 100%;
  height: 100%;
  max-width: 668px;

  width: 100%;

  padding: 37px 0;

  ${respondToWidth.sm`
    padding: 0;
  `};
`;

const containerCSS = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0;
  padding-block: 40px;
  padding-right: 4px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${respondToWidth.s`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    border-radius: 0;
  `};
`;

const headerContainerCSS = css`
  ${respondToWidth.s`
    margin-bottom: 28px;
  `};
`;

const closeButtonCSS = css`
  border: 1px solid;
  border-color: #ffffff;
  background-color: ${({ theme: { colors } }) => colors.primary[1]};
  ${respondToWidth.sm`
    top: 40px;
    right: 16px;
  `};
`;

const titleCSS = css`
  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_3_bold_28};
  `};

  ${({ theme: { typography, colors } }) => respondToWidth.s`
    width: 100%;
    text-align: center;
    color: ${colors.primary[1]};
    ${typography.title_3_bold_28};
  `};
`;

const Content = styled(ScrollbarContainer)`
  width: 100%;
  padding-inline: 100px 96px;
  height: 100%;
  overflow: unset;

  ${respondToWidth.sm`
    padding-inline: 40px 36px;

  `};
  ${respondToWidth.s`
    padding-inline: 16px 12px;
  `};
`;

const ErrorText = styled(StyledText)`
  text-align: start;

  ${({ theme: { colors, typography } }) => css`
    color: ${colors.system.red};
    ${typography.body_basic_medium_14}

    ${respondToWidth.s`
      ${typography.footnote_semibold_12}
  `}
  `};
`;

const DescriptionContainer = styled.div<{ mainColor: string | undefined }>`
  margin-bottom: 48px;
  margin-right: -4px;
  margin-top: -73px;
  background-color: ${({ mainColor }) => mainColor};

  ${({ theme: { typography } }) => respondToWidth.sm`
    ${typography.title_3_bold_28};
    margin-bottom: 20px;
  `};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`;

const Footer = styled.div`
  display: grid;
  grid-gap: 28px;
  height: fit-content;

  ${respondToWidth.s`
      grid-gap: 16px;
  `}
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Link = styled(StyledText)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;

  color: ${({ theme: { colors } }) => colors.primary[1]};
`;

const Text = styled(StyledText)`
  text-align: center;
  margin-top: 20px;
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  ${({ theme: { typography } }) => typography.body_basic_medium_14};
`;

const StripeFormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: stretch;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: calc(50% - 31px);
  top: calc(50% - 21px);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 35px;
  cursor: pointer;
  padding-right: 8px;

  ${respondToWidth.s`
  margin-top: 20px;
  margin-bottom: 23px;
`}
`;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 40px;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const CourseNameText = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const CourseSubNameText = styled.span`
  display: flex;
  height: 28px;
  padding: 0px 5px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: -10px;
  text-align: center;
  padding-bottom: 10px;
  cursor: default;
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.primary[1]};
    background-color: ${colors.neutrals[11]};
    ${typography.title_5_bold_20}
  `};
`;

const SecureText = styled(StyledText)`
  color: ${({ theme: { colors } }) => colors.neutrals[4]};
  margin-left: 6px;
`;

const SecureTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;
